import '../Styles/PrivacyPolicy.css';
export default function PrivacyPolicy() {
    return <>
        <div class="privacy-container">
            <h1 class="privacy-title">Privacy Policy</h1>
            <p class="privacy-intro">
                This Privacy Policy (“Privacy Policy/Policy”) discloses the privacy practices for “BookR”, (“We/we” or “Us/us”) with regard to Your (“You/you” or “Your/your”) use of the online platform BookR (“Mobile Application”). This Privacy Policy describes our practices regarding, including your choices in relation to how we collect, store, use, share and secure your personal information across our mobile application. It also describes your choices regarding use, access and correction of your personal information and your rights in relation to your personal information and how to contact us or supervisory authorities in the event you have a complaint.
            </p>
            <p class="privacy-intro">
                By visiting this Mobile Application, you agree to be bound by the terms and conditions of this Privacy Policy. Please read this Privacy Policy carefully. By using the Mobile Application, you indicate that you understand, agree and consent to this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use/access this Mobile Application.
            </p>
            <p class="privacy-intro">
                We’ll review this Privacy Policy from time to time to make sure it is up-to-date. If you are just a visitor, then please note that this Privacy Policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this Policy periodically. If you are our registered user, we will notify you before we make changes to this Policy and give you the opportunity to review the revised Policy before you choose to continue using our services.
            </p>
            <p class="privacy-intro">
                By Personal Data, we mean any information that can either itself identify you as an individual ("Personally Identifying Information") or that can be connected to you indirectly by linking it to Personally Identifying Information.
            </p>

            <h2 class="section-title">Collection of Information</h2>
            <p class="section-text">
                As a visitor, you can browse our Mobile Application to find out more about our Mobile Application. You are not required to provide us with any Personal Data as a visitor. When you visit the Application, we collect and store certain information in order to improve security, analyse trends and administer the Mobile Application with a view to assist us in improving customer experience. We use this information to evaluate traffic patterns on our Mobile Application so that we can make it more useful to our visitors.
            </p>
            <p class="section-text">
                We collect your Personal Data when you register with us, when you express an interest in obtaining information about us or our products and services, when you participate in activities on our Mobile Application or otherwise contact us.
            </p>
            <p class="section-text">
                We will only collect your Personal Data if we have a proper reason for doing so, e.g.: to comply with our legal and regulatory obligations; for the performance of our contract with you or to take steps at your request before entering into a contract; for our legitimate interests or those of a third party; or where you have given consent. A legitimate interest is when we have a business or commercial reason to use your information, so long as this is not overridden by your own rights and interests. In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person (for example, to prevent payment fraud or confirm your identity).
            </p>
            <p class="section-text">
                Our primary goal in collecting your Personal Data and other personal information is to provide you a safe, efficient, smooth and customized experience. This allows us to provide books, resources that most likely meet your needs and to customize our Mobile Application to make your experience safer and easier. We also use your Personal Identifiable Information together with other Personally Non-Identifiable Information to help us better understand our users and to improve the content and functionality of our Mobile Application. The information we learn from you helps us personalize and continually improve your experience at our Mobile Application. We do not voluntarily make this information available to third parties, nor do we use it for any other purpose, except as set out herein.
            </p>

            <h2 class="section-title">Usage and Retention of Information</h2>
            <p class="section-text">
                We use the personal information we collect, including your Personal Data where it is necessary to deliver the services you have requested, where it is necessary to exercise or comply with legal rights or obligations or for normal business purposes of the kind set out in this Policy.
            </p>
            <p class="section-text">
                We will use your personal information to provide, analyse, administer and improve our services, to provide you with a personalized experience on our Mobile Application (especially, by offering you services that is best suited for you), to contact you about your account and our services, to provide you customer service, to provide you with personalized marketing and to detect, prevent, mitigate and investigate fraudulent or illegal activities.
            </p>

            <h2 class="section-title">Children's Privacy</h2>
            <p class="section-text">
                Our services are not intended for use by children under the age of 9 ("Children"). We do not knowingly collect personal information from children under 9 without parental consent. If you are a parent or guardian and you are aware that your child has provided us with personal information without your consent, please contact us immediately.
            </p>

            <h2 class="section-title">Security</h2>
            <p class="section-text">
                We shall try to take all precautions to protect the personal information both online and offline. We will try to protect your information using technical and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. We have standard SSL certification which basically helps us create a secure connection between our server and user to render any information or action. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centres and information access authorization controls. Only user passwords are encrypted in 64-bit encryption and stored because generally users use the same password on multiple Applications, to prevent any kind of theft, piracy or unauthorised access. If you believe your account has been abused or hacked, please contact us by sending us an email at <a href="mailto:sanket@bookr.co.in" class="contact-link">sanket@bookr.co.in</a>.
            </p>

            <h2 class="section-title">Cookies</h2>
            <p class="section-text">
                We transfer cookies, which are small files containing a string of character, to your IP address, giving the browser distinct identification, in order to keep track of the user’s preferences. Furthermore, these files also help in logging-in faster and they act as a mechanism to determine user trends. The data thus retrieved from the user’s IP address, enables us to enhance our offers, including but not limited to more content in areas of greater interest to a majority of users.
            </p>

            <h2 class="section-title">Third Parties and Links</h2>
            <p class="section-text">
                We may pass your details to other companies in our group. We may also pass your details to our agents and subcontractors to help us with any of our uses of your data set out in our Privacy Policy. For example, we may use third parties to assist us with delivering services to you, to help us to collect payments from you, to analyse data and to provide us with marketing or customer service assistance. We may exchange information with third parties for the purposes of fraud protection and credit risk reduction. We may transfer our databases containing your personal information if we sell our business or part of it. Other than as set out in this Privacy Policy, we shall NOT sell or disclose your personal data to third parties without obtaining your prior consent unless it is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law.
            </p>

            <h2 class="section-title">Grievance Officer</h2>
            <p class="section-text">
                In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                <a href="mailto:sanket@bookr.co.in" class="contact-link">sanket@bookr.co.in</a>.
            </p>
        </div>
    </>
}