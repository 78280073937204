import '../Styles/CancellationPolicy.css';

export default function CancellationPolicy() {
    return (
        <>
            <div class="cancellation-container">
                <h1 class="cancellation-title">Cancellation Policy</h1>
                <p class="cancellation-intro">
                    You can cancel your BookR subscription at any time, and you will continue to have access to the BookR service through the end of your billing period. To the extent permitted by the applicable law, payments are non-refundable, and we do not provide refunds or credits for any partial membership periods or unread books.
                </p>

                <h2 class="section-title">How to Cancel Your Subscription</h2>
                <p class="section-text">
                    To cancel your BookR subscription, follow these steps:
                </p>
                <ol class="steps-list">
                    <li class="step-item">Go to the <strong>"Manage Subscriptions"</strong> page.</li>
                    <li class="step-item">Follow the instructions for cancellation.</li>
                </ol>
                <p class="section-text">
                    If you cancel your membership, your account will automatically close at the end of your current billing period.
                </p>

                <h2 class="section-title">Billing Details</h2>
                <p class="section-text">
                    To see when your account will close, click <strong>"Billing details"</strong> on the <strong>"Account"</strong> page.
                </p>


                <h2 class="section-title">Contact Us</h2>
                <p class="section-text">
                    If you have any questions or need assistance with canceling your subscription, please contact us at <a href="mailto:sanket@bookr.co.in" class="contact-link">sanket@bookr.co.in</a>.
                </p>
            </div>
        </>
    )
}