import "../Styles/SharingPolicy.css";
export default function SharingPolicy() {
    return (
        <>
            <div class="sharing-container">
                <h1 class="sharing-title">BookR Book Sharing Policy</h1>
                <p class="sharing-intro">
                    BookR is a peer-to-peer book sharing platform that facilitates the exchange of books between Users ("Borrowers" and "Owners"). This Book Sharing Policy sets out the guidelines and expectations for both Borrowers and Owners to ensure a fair and positive book-sharing experience for all participants.
                </p>

                <h2 class="section-title">1. Introduction</h2>
                <p class="section-text">
                    BookR is a peer-to-peer book sharing platform that facilitates the exchange of books between Users ("Borrowers" and "Owners"). This Book Sharing Policy sets out the guidelines and expectations for both Borrowers and Owners to ensure a fair and positive book-sharing experience for all participants.
                </p>

                <h2 class="section-title">2. Borrower Responsibilities</h2>
                <h3 class="subsection-title">2.1 Book Care</h3>
                <p class="subsection-text">
                    Borrowers are responsible for treating borrowed books with respect and care. This includes keeping the book in good condition, avoiding damage, markings, or excessive wear and tear.
                </p>
                <h3 class="subsection-title">2.2 Timely Return</h3>
                <p class="subsection-text">
                    Borrowers must return the borrowed book by the agreed-upon due date. If an extension is needed, the Borrower should communicate with the Owner and seek their approval in advance.
                </p>
                <h3 class="subsection-title">2.3 Late Returns</h3>
                <p class="subsection-text">
                    Borrowers who fail to return books on time may be subject to penalties, such as additional fees or temporary suspension of borrowing privileges. It is the Borrower's responsibility to communicate any potential delays to the Owner promptly.
                </p>

                <h2 class="section-title">3. Owner Responsibilities</h2>
                <h3 class="subsection-title">3.1 Book Availability</h3>
                <p class="subsection-text">
                    Owners should ensure that the books they list on BookR are available for borrowing. They should promptly remove books from the platform that are no longer available or temporarily unavailable for borrowing.
                </p>
                <h3 class="subsection-title">3.2 Book Condition</h3>
                <p class="subsection-text">
                    Owners must share books that are in good condition, suitable for lending purposes. They should ensure that books are clean, readable, and free from significant damage or missing pages.
                </p>
                <h3 class="subsection-title">3.3 Clear Book Descriptions</h3>
                <p class="subsection-text">
                    Owners should provide accurate and detailed descriptions of the books they share on the platform, including any relevant information about the book's condition, edition, or additional materials (e.g., dust jacket, annotations).
                </p>
                <h3 class="subsection-title">3.4 Response to Borrower Requests</h3>
                <p class="subsection-text">
                    Owners should promptly respond to Borrower requests to borrow their books, either accepting or declining the request within a reasonable timeframe.
                </p>

                <h2 class="section-title">4. Book Sharing Process</h2>
                <h3 class="subsection-title">4.1 Booking Requests</h3>
                <p class="subsection-text">
                    Borrowers can request to borrow a book from an Owner through the BookR platform. The Borrower should provide a clear and concise message outlining their request.
                </p>
                <h3 class="subsection-title">4.2 Owner Confirmation</h3>
                <p class="subsection-text">
                    Owners have the discretion to accept or decline a booking request based on the availability of the book and other relevant factors. Owners should respond promptly to booking requests.
                </p>
                <h3 class="subsection-title">4.3 Communication</h3>
                <p class="subsection-text">
                    Once the booking request is accepted, Borrowers and Owners should establish clear communication channels to coordinate the logistics of book pick-up, return, and any necessary extensions.
                </p>
                <h3 class="subsection-title">4.4 Book Return</h3>
                <p class="subsection-text">
                    Borrowers must return the book to the Owner in the same condition it was received, taking reasonable precautions to avoid damage during transit.
                </p>

                <h2 class="section-title">5. Dispute Resolution</h2>
                <h3 class="subsection-title">5.1 Communication</h3>
                <p class="subsection-text">
                    In the event of a dispute between Borrowers and Owners, both parties should first attempt to resolve the issue through respectful and open communication, seeking a mutually satisfactory solution.
                </p>
                <h3 class="subsection-title">5.2 Mediation</h3>
                <p class="subsection-text">
                    If the dispute remains unresolved, BookR may, at its discretion, mediate the dispute to facilitate a fair resolution. Users agree to cooperate with BookR in such instances.
                </p>

                <h2 class="section-title">6. Compliance with Copyright Laws</h2>
                <h3 class="subsection-title">6.1 Legal Sharing</h3>
                <p class="subsection-text">
                    Users must respect copyright laws and only share or request books that they have the legal right to share or borrow. Users are prohibited from sharing or requesting copyrighted materials without proper authorization.
                </p>
                <h3 class="subsection-title">6.2 Liability</h3>
                <p class="subsection-text">
                    BookR assumes no liability for any copyright infringement by Users. Users are solely responsible for ensuring their book sharing activities comply with applicable copyright laws.
                </p>

                <h2 class="section-title">7. Code of Conduct</h2>
                <h3 class="subsection-title">7.1 Respectful Behavior</h3>
                <p class="subsection-text">
                    Users must conduct themselves in a respectful manner when interacting with other Users. Any form of harassment, discrimination, or abusive behavior is strictly prohibited.
                </p>
                <h3 class="subsection-title">7.2 Compliance with Policies</h3>
                <p class="subsection-text">
                    Users must comply with all BookR policies, including the Terms and Conditions, Book Sharing Policy, and any additional guidelines or rules provided by BookR.
                </p>

                <h2 class="section-title">8. Policy Violations and Consequences</h2>
                <h3 class="subsection-title">8.1 Violations</h3>
                <p class="subsection-text">
                    Users found to be in violation of this Book Sharing Policy or any other BookR policies may face consequences, including but not limited to warnings, temporary or permanent suspension of their account, or legal actions if applicable.
                </p>

                <h2 class="section-title">9. Amendments</h2>
                <p class="section-text">
                    BookR reserves the right to update or modify this Book Sharing Policy at any time. Users will be notified of any significant changes, and continued use of the Service after such modifications constitute acceptance of the updated policy.
                </p>

                <p class="section-text">
                    Please familiarize yourself with this Book Sharing Policy and adhere to its guidelines when participating in book sharing activities on the BookR platform.
                </p>
            </div>
        </>
    )
}