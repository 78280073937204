import React, { useState } from "react";
import '../Styles/FAQ.css';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is BookR?",
      answer:
        "BookR is a subscription-based book reading app designed to connect book enthusiasts and facilitate access to physical books for readers.",
    },
    {
      question: "How does BookR work?",
      answer:
        "BookR is a book library app where users can access all the paperback books available on the app. Based on reading habits, users can select the most appropriate plan and subscribe to the BookR app. Then, they can select the books they want to read and order them. The book will be delivered to the address provided. Once the user finishes reading, they can mark the book as read, and it will be picked up from the user.",
    },
    {
      question: "Is BookR available across India?",
      answer: "Yes, BookR is available across India.",
    },
    {
      question: "What are the benefits of using BookR?",
      answer: (
        <ul>
          <li>Access to a diverse range of books without the need to purchase them.</li>
          <li>Opportunity to connect with fellow readers and discuss shared interests.</li>
          <li>Reduce environmental impact by promoting the reuse of books.</li>
          <li>Save money by borrowing books instead of buying them.</li>
        </ul>
      ),
    },
    {
      question: "How do I join BookR?",
      answer:
        "You can download the BookR app from the App Store or Google Play Store, create an account, and subscribe to a membership plan to start accessing books.",
    },
    {
      question: "Is there a cost to use BookR?",
      answer:
        "Yes, BookR operates on a subscription-based model. Members pay a monthly or annual fee to access the platform's features and borrow books from other users.",
    },
    {
      question: "Do I need to pay extra for logistics?",
      answer:
        "No, BookR only charges subscription costs, and all costs are included in it. Users do not have to pay anything for logistics, late fees, or any other costs.",
    },
    {
      question: "How do I list my books on BookR?",
      answer:
        "After signing up and logging into your account, you can add books to your virtual library by scanning the ISBN code of the book available on the back side. You can also type the ISBN code of the book in the app, and it will be added to your BookR library.",
    },
    {
      question: "What happens if a borrowed book is damaged or lost?",
      answer:
        "Users are responsible for taking care of borrowed books and returning them in the same condition they received them. In the event of damage or loss, borrowers may be required to compensate based on the app's policies.",
    },
    {
      question: "Is there a limit to how many books I can borrow at a time?",
      answer:
        "The borrowing limits may vary depending on your subscription plan. Generally, users can borrow multiple books simultaneously, but there may be restrictions based on availability and user preferences.",
    },
    {
      question: "Can I request specific books that are not currently available on BookR?",
      answer:
        "Yes, users can submit requests for specific books they would like to read. The requested book will be available on the app for the user to order. However, ordering the book will depend on the subscription plan the user is subscribed to.",
    },
    {
      question: "How is the privacy and security of user information ensured on BookR?",
      answer:
        "BookR prioritizes the privacy and security of user data and employs robust measures to safeguard personal information. We adhere to strict data protection policies and use encryption and other technologies to secure user transactions and interactions within the app.",
    },
  ];

  return (
    <div className="faq-container">
      <h1>Frequently Asked Questions (FAQs) for BookR</h1>
      <div className="accordion">
        {faqData.map((item, index) => (
          <div className="accordion-item" key={index}>
            <button
              className={`accordion-question ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span className="accordion-icon">{activeIndex === index ? "-" : "+"}</span>
            </button>
            <div
              className="accordion-answer"
              style={{
                maxHeight: activeIndex === index ? "500px" : "0",
                padding: activeIndex === index ? "15px" : "0 15px",
              }}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;