import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import SharingPolicy from "./Pages/SharingPolicy";
import CancellationPolicy from "./Pages/CancellationPolicy";
import FAQ from "./Pages/FAQ";

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/sharing-policy" element={<SharingPolicy />} />
          <Route path="/book-sharing-policy" element={<SharingPolicy />} />
          <Route path="/cancellation-policy" element={<CancellationPolicy />} />
          <Route path="/faq" element={<FAQ/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;