import '../Styles/TermsAndConditions.css';
function TermsAndConditions() {
    return (
        <div>
            <div class="terms-container">
                <h1 class="terms-title">Terms and Conditions</h1>
                <p class="terms-intro">Welcome to BookR! These Terms and Conditions ("Terms") govern your use of the BookR platform, a subscription-based peer-to-peer book sharing service ("Service"), operated by BookR Inc. ("BookR," "we," or "us"). By accessing or using the Service, you ("User" or "you") agree to comply with these Terms. Please read them carefully before using the Service.</p>

                <h2 class="section-title">1. Acceptance of Terms</h2>
                <p class="section-text">By accessing or using the Service, you acknowledge that you have read, understood, and agree to be bound by these Terms, including any additional guidelines, policies, or rules applicable to specific features or services provided by BookR. If you do not agree with these Terms, you must not use the Service.</p>

                <h2 class="section-title">2. User Eligibility</h2>
                <h3 class="subsection-title">2.1 Age Requirement</h3>
                <p class="subsection-text">By using the Service, you represent and warrant that you are at least 18 years old. If you are under 18 years old, you may only use the Service with the involvement and consent of a parent or guardian.</p>
                <h3 class="subsection-title">2.2 Account Registration</h3>
                <p class="subsection-text">To use the Service, you must create an account and provide accurate and complete information. You are responsible for maintaining the confidentiality of your account information and are liable for all activities that occur under your account.</p>

                <h2 class="section-title">3. Subscription and Fees</h2>
                <h3 class="subsection-title">3.1 Subscription</h3>
                <p class="subsection-text">BookR offers a subscription-based model for accessing and sharing books. By subscribing to the Service, you gain access to the book-sharing platform and related features, subject to these Terms.</p>
                <h3 class="subsection-title">3.2 Payment</h3>
                <p class="subsection-text">You agree to pay the subscription fees as outlined on the BookR website. Payment details and methods will be specified during the registration process.</p>
                <h3 class="subsection-title">3.3 Automatic Renewal</h3>
                <p class="subsection-text">Subscriptions are automatically renewed unless you cancel your subscription before the renewal date. You will be charged the applicable subscription fee for each renewal period.</p>
                <h3 class="subsection-title">3.4 Extension of Offering</h3>
                <p class="subsection-text">Time taken in procuring the requested books may lead to delay in the delivery. For such causes the user will not get any extension of offering beyond the subscription.</p>
                <h3 class="subsection-title">3.5 Refunds</h3>
                <p class="subsection-text">Subscription fees are non-refundable unless expressly stated otherwise in these Terms or as required by applicable law.</p>

                <h2 class="section-title">4. Book Sharing</h2>
                <h3 class="subsection-title">4.1 User Obligations</h3>
                <p class="subsection-text">As a User of the Service, you may share and borrow books from other Users, subject to the limitations and rules set forth by BookR.</p>
                <h3 class="subsection-title">4.2 Book Condition</h3>
                <p class="subsection-text">Users agree to maintain the books they share in good condition, free from damage, markings, or excessive wear and tear.</p>
                <h3 class="subsection-title">4.3 Liability</h3>
                <p class="subsection-text">BookR is not responsible for the condition or quality of the books shared on the platform. Users share and borrow books at their own risk.</p>
                <h3 class="subsection-title">4.4 Copyright Compliance</h3>
                <p class="subsection-text">Users must respect copyright laws and not share or request copyrighted materials without proper authorization.</p>

                <h2 class="section-title">5. Prohibited Conduct</h2>
                <h3 class="subsection-title">5.1 Misuse</h3>
                <p class="subsection-text">Users must not use the Service for any unlawful, fraudulent, or unauthorized purposes. This includes but is not limited to unauthorized access, interference, or distribution of harmful software.</p>
                <h3 class="subsection-title">5.2 Content Restrictions</h3>
                <p class="subsection-text">Users must not upload, share, or request content that is offensive, abusive, defamatory, infringing, or violates the rights of others.</p>
                <h3 class="subsection-title">5.3 Data Privacy</h3>
                <p class="subsection-text">Users must not attempt to access or collect personal information from other Users without their consent.</p>

                <h2 class="section-title">6. Intellectual Property</h2>
                <h3 class="subsection-title">6.1 BookR Ownership</h3>
                <p class="subsection-text">BookR retains all rights, titles, and interests in the Service, including all intellectual property rights. Users do not acquire any ownership rights by using the Service.</p>
                <h3 class="subsection-title">6.2 User Content</h3>
                <p class="subsection-text">By sharing or uploading content on the Service, you grant BookR a non-exclusive, worldwide, royalty-free license to use, modify, distribute, and display the content for the purposes of providing the Service.</p>

                <h2 class="section-title">7. Termination</h2>
                <h3 class="subsection-title">7.1 User Termination</h3>
                <p class="subsection-text">You may terminate your account at any time by following the instructions provided on the Service. All provisions of these Terms that should reasonably survive termination will continue to apply.</p>
                <h3 class="subsection-title">7.2 BookR Termination</h3>
                <p class="subsection-text">BookR reserves the right to suspend or terminate your account and access to the Service at its sole discretion, without prior notice or liability.</p>

                <h2 class="section-title">8. Disclaimer of Warranties</h2>
                <p class="section-text">The Service is provided on an "as is" and "as available" basis. BookR does not warrant that the Service will be error-free, uninterrupted, or free from harmful components. Users use the Service at their own risk.</p>

                <h2 class="section-title">9. Limitation of Liability</h2>
                <p class="section-text">To the maximum extent permitted by law, BookR shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from your use of the Service or any loss or damage related to the books shared on the platform.</p>

                <h2 class="section-title">10. Modifications</h2>
                <p class="section-text">BookR reserves the right to modify or update these Terms at any time, with or without notice. Continued use of the Service after such modifications constitutes your acceptance of the updated Terms.</p>

                <h2 class="section-title">11. Governing Law</h2>
                <p class="section-text">These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where BookR is registered, without regard to its conflict of laws principles.</p>

                <h2 class="section-title">12. Entire Agreement</h2>
                <p class="section-text">These Terms constitute the entire agreement between you and BookR regarding the use of the Service, superseding any prior agreements or understandings.</p>

                <p class="contact-info">If you have any questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:sanket@bookr.co.in" class="contact-link">sanket@bookr.co.in</a>.</p>
            </div>
        </div>
    );
}

export default TermsAndConditions;